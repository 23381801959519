<template>
    <div>
        <div id="reg-step1" class="merchant-reg-wrapper col-md-12">
            <div id="merchant-container-step1" class="merchant-reg-container">
                    <div class=" merchant-login">
                            <step1></step1>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import Step1 from "@/views/Merchant/Register/Step1.vue"
export default {
    components: {
        Step1
    },
    created(){},
    mounted: function() {},
    methods:{}
}
</script>
<style scoped>
/* New Styles start*/
.merchant-reg-wrapper{
    display: flex;
}

#merchant-container-step1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
}

#reg-step1 {
    background: url('https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/v1630063322/cochchi/mercahnt/1630063320641.webp') no-repeat;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 823px;
}
@media screen and (max-width: 1575px){
    .merchant-reg-container{
        flex-basis: 75%;
    }
}
@media screen and (max-width: 1475px){
    .merchant-reg-container{
        flex-basis: 85%;
    }
}
@media screen and (max-width: 1375px){
    .merchant-reg-container{
        flex-basis: 95%;
    }

}
@media screen and (max-width: 1275px){
    .merchant-reg-container{
        flex-basis: 97%;
    }

}
/* New Styles end*/

.justify-content-otp{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84vh;
}
@media (max-width: 700px) {
  .justify-content-otp{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
    .justify-content-otp{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }
}
@media screen and (max-width: 1440px) {
    #reg-step1 {
        height: 750px;
    }

    #merchant-container-step1{
        padding: 10%;
    }
}

@media screen and (max-width: 768px) {
    #merchant-container-step1{
        flex-basis: 100%;
        padding: 10%;
    }
    
}

@media screen and (max-width: 550px) {
    #reg-step1 {
        background-position: left;
        height: auto;
    }

    .merchant-reg-inner-left .mr-form-top-area img {
        height: 120px;
    }

    #merchant-container-step1 {
        padding: 5%;
    }

}
</style>