import Domain from "../domain";
export default {
  methods: {
    forgetPasswordSetFunctionMerchant: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/forgot/password/set",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantCheckUser: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/check/user",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantRegister: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/register",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    setMerchantPasswordApi: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/set/password",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantMobileVerify: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/verify/mobile",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantChangeMobile: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/change/mobile",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantChangeMobileProfileApi: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/profile/new/mobile/otp",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantProfileVeryfyOtpApi: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/profile/new/mobile/otp/verify",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantMobileResend: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/auth/otp/resend/"+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantLogin: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/auth/login",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    firebaseLogin: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "common/oauth/login",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    }
  }
};
