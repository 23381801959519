<template>
    <div class="merchant-reg-inner-wrapper">
        <div class="merchant-reg-inner-left">
            <div class="rectangle-reg pt-img">
                    <div class="mr-form-top-area">
                        <img :src="this.$store.state.colourLogo" alt="">
                    </div>
                    <div class="mr-form-area">
                    <b-row style="padding-top: 10px; opacity:100%;">
                        <div class="col-sm-12">
                            <p class="create-text">
                                Create your merchant account from here. Explore all the tools and services you need to start, run, and grow your business!
                            </p>
                        </div>
                    </b-row>
                    <b-row style="padding-top: 10px; opacity:100%;">
                        <div class="col-sm-12">
                            <input type="text" required v-model="form.first_name" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"  value="" placeholder="First Name" id="input-email" class="form-control input-lg">
                        </div>
                    </b-row>
                    <b-row style="padding-top: 10px; opacity:100%;">
                        <div class="">
                            <div class="col-sm-12">
                            <input type="text" required v-model="form.last_name" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"  value="" placeholder="Last Name" class="form-control input-lg">
                            </div>
                        </div>
                    </b-row>
                    <b-row style="padding-top: 10px; opacity:100%;">
                        <div class="">
                            <div class="col-sm-12">
                            <input type="email" @input="validEmail(form.email)"  :style="changeColor" required v-model="form.email" value="" placeholder="Email" class="form-control input-lg">
                            </div>
                        </div>
                    </b-row>
                    <b-row style="padding-top: 10px; opacity:100%;">
                        <b-col md="12" style="padding-top: 0px;">
                                <input type="button" :disabled="!formIsValid" @click="continueRegistration()" style="width: 100%;" value="Create Your Store" class="btn merchant-custom-btn btn-lg">
                        </b-col>
                    </b-row>
                    <div class="already-acc">
                        <a @click="goHome()">Already have an e-commerce account?</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="merchant-reg-inner-right"></div> -->
    </div>
</template>
<script>
import mixinAuth from "@/mixins/merchant/APIAuth";
export default {
    mixins: [mixinAuth],
    data(){
        return{
            showReCapture: true,
            disable: true,
            changeColor: {
                border: "1px solid #ccc"
            },
            form: {
                first_name: "",
                last_name: "",
                email:""
            }
        }
    },
    components: {},
    created(){},
    computed: {
        formIsValid() {
            return (
                this.form.first_name &&
                this.form.last_name &&
                this.validEmail()
            );
        }
    },
    mounted: function() {},
    methods:{
        goHome () {
            this.$router.push({ name: 'Merchant Landing New' });
        },
        continueRegistration: async function () {

            try {
                let userObj = {}
                userObj.username = this.form.email;
                let res = await this.merchantCheckUser(userObj);
                if (res) {
                    this.$swal.fire({
                    position: "center",
                    type: "error",
                    title: "User already exists. Please use another email.",
                    showConfirmButton: false,
                    timer: 1500,
                    });    
                }
                
                // localStorage.setItem("merchat_form", JSON.stringify(this.form));
                // this.$router.push({ name: 'Merchant Register Step2', params: { object: this.form } });

            } catch (error) {
                localStorage.setItem("merchat_form", JSON.stringify(this.form));
                this.$router.push({ name: 'Merchant Register Step2', params: { object: this.form } });
            }

        },
        validEmail(){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email))
            {
                this.changeColor.border = "1px solid #ccc";
                return true;
            } else {
                this.changeColor.border = "1px solid red";
                return false;
            }
        },
    }
}
</script>
<style scoped>
.horizontal-align{
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.image-bck{
    /* background-image: url('https://res.cloudinary.com/persystance-pvt/image/upload/v1578481424/oeqgmsitdfw7dcfd1uvn.jpg'); */
    height: 46vh;
    width: 100%;
    /* background-repeat: no-repeat; */
}

.mr-form-top-area {
    padding: 2% 10% 0% 10%;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.mr-form-area {
    padding: 0% 10% 10% 10%;
}

.rectangle-reg{
  width: 100%;
  height: auto;
  background-color: rgba(37, 130, 203, 0.7);
  border-radius: 5px;
}
.ecommerce_logo_black {
  width: 40%;
}
/* .pt-img {
    padding: 10%;
} */
.input-fields {
    height:40px;
}
.p1 {
  padding-top: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2e2e2e;
}

/* New Styles start*/
.merchant-reg-inner-wrapper{
    display: flex;
    justify-content: space-between;
}

.merchant-reg-inner-left{
    color: #2e2e2e;
}
.merchant-reg-inner-left .rectangle-reg{
    width: 100% !important;
}
.merchant-reg-inner-left .pt-img{
    margin-top: 0px !important;
}
.merchant-reg-inner-right{
    flex: 32%;
    border-radius: 5px;
    background: red;
    background: url('../../../../src/assets/image/register-new.png') no-repeat;
    background-size: cover;
    background-position: -20px 0px;
}
.merchant-reg-inner-left .input-lg{
    height: 50px !important;
    margin-bottom: 15px;
}
.merchant-reg-inner-left .mr-form-top-area{
    text-align: center;
}
.merchant-reg-inner-left .mr-form-top-area img{
    display: inline-block;
    margin-bottom: 20px;
    font-family: 'Poppins';
    height: 170px;
}
.merchant-reg-inner-left .mr-form-top-area p{
    font-size: 14px;
    margin-bottom: 20px;
}
.merchant-reg-inner-left .already-acc{
    text-align: center;
    margin-top: 25px;
}
.merchant-reg-inner-left .already-acc a{
    color: white;
    font-size: 14px;
    text-decoration: none;
}
.merchant-reg-inner-left .already-acc a:hover{
    color: #000000;
}
.merchant-custom-btn{
    background: #114B86;
    color: white;
    border: none;
}
.create-text{
    color: white;
    text-align: center;
}
/* New Styles end*/

@media (max-width: 700px) {
  .img-bck{
      display: none;
  }
  .rectangle-reg{
    width: 300px;
    height: auto;
    /* background-color: #e8e8e8; */
    border-radius: 5px;
    margin-top: 2vh;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .img-bck{
      /* display: none; */
  }
  .rectangle-reg{
    width: 100%;
    /* width: 500px; */
    height: auto;
    /* background-color: #e8e8e8; */
    border-radius: 5px;
    margin-top: 2vh;
  }
}

@media screen and (max-width: 550px) {
    .merchant-reg-inner-left .mr-form-top-area img {
        height: 120px;
    }

}
</style>